define("discourse/plugins/discourse-multilingual/discourse/templates/components/category-name-fields", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field">
    {{#unless this.category.isUncategorizedCategory}}
      <section class="field-item category-name">
        <label>{{i18n "category.name"}}</label>{{#if
          this.category.name_translations
        }}<span>{{d-icon "translate"}}</span>{{/if}}
        {{text-field
          value=this.category.name
          placeholderKey="category.name_placeholder"
          maxlength="50"
        }}
      </section>
    {{/unless}}
    <section class="field-item">
      <label>{{i18n "category.slug"}}</label>
      {{text-field
        value=this.category.slug
        placeholderKey="category.slug_placeholder"
        maxlength="255"
      }}
    </section>
  </section>
  */
  {
    "id": "WhEwfa7q",
    "block": "[[[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"category\",\"isUncategorizedCategory\"]]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field-item category-name\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,3],[\"category.name\"],null]],[13],[41,[30,0,[\"category\",\"name_translations\"]],[[[10,1],[12],[1,[28,[35,6],[\"translate\"],null]],[13]],[]],null],[1,\"\\n      \"],[1,[28,[35,7],null,[[\"value\",\"placeholderKey\",\"maxlength\"],[[30,0,[\"category\",\"name\"]],\"category.name_placeholder\",\"50\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"section\"],[14,0,\"field-item\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,3],[\"category.slug\"],null]],[13],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"value\",\"placeholderKey\",\"maxlength\"],[[30,0,[\"category\",\"slug\"]],\"category.slug_placeholder\",\"255\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"section\",\"unless\",\"label\",\"i18n\",\"if\",\"span\",\"d-icon\",\"text-field\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/category-name-fields.hbs",
    "isStrictMode": false
  });
});