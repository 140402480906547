define("discourse/plugins/discourse-multilingual/discourse/templates/components/language-switcher-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.visibleLanguages as |l|}}<div
      class="{{l.class}} ls-language"
      {{action "change" l.locale}}
      role="button"
    >{{l.name}}</div>{{/each}}
  
  {{#if this.showHiddenToggle}}
    {{d-button icon="plus" action=(action "toggleHidden") class="toggle-hidden"}}
  
    {{#if this.showHidden}}
      <div class="hidden-languages">
        <ul>
          {{#each this.hiddenLanguages as |l|}}<li><div
                class="ls-language"
                {{action "change" l.locale}}
                role="button"
              >{{l.name}}</div></li>{{/each}}
        </ul>
      </div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "zE62xuPw",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"visibleLanguages\"]]],null]],null],null,[[[11,0],[16,0,[29,[[30,1,[\"class\"]],\" ls-language\"]]],[24,\"role\",\"button\"],[4,[38,3],[[30,0],\"change\",[30,1,[\"locale\"]]],null],[12],[1,[30,1,[\"name\"]]],[13]],[1]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showHiddenToggle\"]],[[[1,\"  \"],[1,[28,[35,5],null,[[\"icon\",\"action\",\"class\"],[\"plus\",[28,[37,3],[[30,0],\"toggleHidden\"],null],\"toggle-hidden\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showHidden\"]],[[[1,\"    \"],[10,0],[14,0,\"hidden-languages\"],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n        \"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"hiddenLanguages\"]]],null]],null],null,[[[10,\"li\"],[12],[11,0],[24,0,\"ls-language\"],[24,\"role\",\"button\"],[4,[38,3],[[30,0],\"change\",[30,2,[\"locale\"]]],null],[12],[1,[30,2,[\"name\"]]],[13],[13]],[2]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"l\",\"l\"],false,[\"each\",\"-track-array\",\"div\",\"action\",\"if\",\"d-button\",\"ul\",\"li\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/language-switcher-bar.hbs",
    "isStrictMode": false
  });
});