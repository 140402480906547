define("discourse/plugins/discourse-multilingual/discourse/templates/components/multilingual-navigation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="nav nav-pills">
    {{nav-item
      route="adminMultilingualLanguages"
      label="multilingual.languages.title"
    }}
    {{nav-item
      route="adminMultilingualTranslations"
      label="multilingual.translations.title"
    }}
    {{nav-item
      route="tagGroups"
      routeParam=this.contentLanguageTagGroupId
      label="multilingual.content_languages.tags.title"
      icon="tag"
    }}
  </ul>
  */
  {
    "id": "6T7fDehU",
    "block": "[[[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"adminMultilingualLanguages\",\"multilingual.languages.title\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"adminMultilingualTranslations\",\"multilingual.translations.title\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"route\",\"routeParam\",\"label\",\"icon\"],[\"tagGroups\",[30,0,[\"contentLanguageTagGroupId\"]],\"multilingual.content_languages.tags.title\",\"tag\"]]]],[1,\"\\n\"],[13]],[],false,[\"ul\",\"nav-item\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/multilingual-navigation.hbs",
    "isStrictMode": false
  });
});